<template>
  <div class="search_flex">
    <div class="search_left">
      <!-- 战区 -->
      <salesArea
        :isDetailPage="isDetailPage"
        :hval="salesArea"
        @change="salesAreaChange"
        @saleArea="saleArea"
      />
      <selectComp
        v-if="compType !== 'table'"
        :clearable="false"
        :data="statModelList"
        :hval="type"
        @change="typeChange"
        placeholder="统计模式"
      />
      <template v-if="compType !== 'rent-withdrawal'">
        <selectComp
          v-if="compType !== 'duration'"
          placeholder="租赁时长"
          :data="durationTypeList"
          :hval="durationType"
          @change="durationTypeChange"
        />
        <selectComp
          :data="chargebackModelList"
          :hval="chargebackCategory"
          @change="chargebackCategoryChange"
          placeholder="退单模式"
        />
        <selectComp
          placeholder="退单方式"
          :data="chargebackTypeList"
          :hval="chargebackType"
          @change="chargebackTypeChange"
        />
      </template>
      <paramsDictNoForm
        placeholder="退单原因"
        :hval="reason"
        @change="reasonChange"
        :parentCode="101436"
      />
      <selectComp
         v-if="compType !== 'rent-withdrawal'"
        :hval="timeType"
        :data="timeTypeList"
        @change="timeTypeChange"
        :clearable="false"
        placeholder="时间类型"
      />
      <dateComp
        startPlaceholder="退单开始日期"
        endPlaceholder="退单结束日期"
        v-show="timeType === 1 || timeType === 2"
        :hval="date"
        @change="dateChange"
      />
      <div class="month_box" v-show="timeType === 3">
        <el-date-picker
          v-model="beginMonth"
          type="month"
          size="small"
          placeholder="退单起始月"
          :clearable="false"
          style="width:126px"
          @change="beginMonthChange"
        >
        </el-date-picker>
        <div style="margin:0 5px">-</div>
        <el-date-picker
          v-model="endMonth"
          type="month"
          size="small"
          placeholder="退单截止月"
          :clearable="false"
          style="width:126px"
          @change="endMonthChange"
        >
        </el-date-picker>
      </div>
      <!-- 商家搜索 -->
      <merchant
        v-if="!isStore"
        showRankBtn
        :hval="storeName"
        @storeNameChange="storeNameChange"
        @rankClick="isShow = true"
      />
      <btnGroup @search="search" @reset="reset" />
      <slot></slot>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="isShow = false" @selectStore="selectStore" />
  </div>
</template>
<script>
import merchant from '@/views/components/merchant'
import salesArea from '@/views/components/salesArea'
import paramsDictNoForm from '@/views/components/paramsDictNoForm'
import rank from '@/views/data-center/components/rank'
import { formatDate, diffDate, localGet } from '@/utils/common'
import {
  durationTypeList,
  chargebackTypeList,
  statModelList,
  chargebackModelList
} from '@/utils/constData'
export default {
  components: {
    merchant,
    salesArea,
    paramsDictNoForm,
    rank
  },
  props: {
    // 区分哪里用到这个组件
    compType: {
      type: String,
      default: ''
    },
    // 是否是详情页面
    isDetailPage: {
      type: Boolean,
      default: false
    },
    // 详情页面是否传参数
    detailPageQuery: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timeTypeList: [
        { label: '按天', value: 1 },
        { label: '按月', value: 3 }
      ],
      isShow: false,
      storeId: '',
      storeName: '',
      beginMonth: '',
      endMonth: '',
      date: [],
      startTime: '',
      endTime: '',
      salesArea: [],
      level: '',
      cityId: '',
      type: 1,
      timeType: 3,
      durationType: '',
      chargebackCategory: '',
      chargebackType: '',
      reason: ''
    }
  },
  computed: {
    durationTypeList () {
      return durationTypeList
    },
    chargebackTypeList () {
      return chargebackTypeList
    },
    statModelList () {
      return statModelList
    },
    chargebackModelList () {
      return chargebackModelList
    },
    // 是否是商家角色
    isStore () {
      return localGet('loginUserInfo').businessRole === 'store'
    }
  },
  mounted () {
    //  跳转详情页面需要获取query的时间
    this.handleQuery()
  },
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.$emit('saleArea', salesArea, level, cityId)
    },
    init () {
      // 按日统计， 以今天为起点，向前推6天，共计7天。
      // 按月统计，以当前月伟起点，向前推5月，共计6月。
      // 按周统计，以当前周伟起点，向前推5周，共计6周。
      if (this.timeType === 1) {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-6, 'days', true)
        this.date = [diffDate(-6, 'days'), new Date()]
      } else if (this.timeType === 3) {
        this.endMonth = new Date()
        this.beginMonth = diffDate(-5, 'months')
        this.endTime = formatDate(new Date()).slice(0, 7)
        this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      } else {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-7, 'weeks', true)
        this.date = [diffDate(-7, 'weeks'), new Date()]
      }
    },
    //  处理跳转详情页面需要自动获取列表选择的时间
    handleQuery () {
      if (JSON.stringify(this.$route.query) === '{}' || !this.detailPageQuery) {
        this.timeType = 3
        this.init()
      } else {
        // 跳转的详情页面
        const {
          timeType,
          time,
          storeId,
          storeName,
          type,
          durationType,
          chargebackCategory,
          chargebackType,
          reason
        } = this.$route.query
        this.timeType = +timeType
        this.storeId = storeId
        this.storeName = storeName
        this.startTime = time
        this.endTime = time
        this.type = +type
        this.durationType = durationType ? +durationType : ''
        this.chargebackCategory = chargebackCategory ? +chargebackCategory : ''
        this.chargebackType = chargebackType ? +chargebackType : ''
        this.reason = reason ? +reason : ''
        if (+timeType === 3) {
          this.beginMonth = new Date(this.startTime)
          this.endMonth = new Date(this.endTime)
        } else {
          this.date = [new Date(this.startTime), new Date(this.endTime)]
        }
      }
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    typeChange (val) {
      this.type = val
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    durationTypeChange (val) {
      this.durationType = val
    },
    chargebackCategoryChange (val) {
      this.chargebackCategory = val
    },
    chargebackTypeChange (val) {
      this.chargebackType = val
    },
    reasonChange (val) {
      this.reason = val.code
    },
    timeTypeChange (val) {
      this.timeType = val
      this.init()
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    beginMonthChange (val) {
      this.startTime = formatDate(val).slice(0, 7)
    },
    endMonthChange (val) {
      this.endTime = formatDate(val).slice(0, 7)
    },
    search () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        salesArea: this.salesArea,
        type: this.type,
        timeType: this.timeType,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        storeId: this.storeId,
        storeName: this.storeName,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.$emit('search', params)
    },
    reset () {
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea

      this.type = 1
      this.timeType = 3
      this.durationType = ''
      this.chargebackCategory = ''
      this.chargebackType = ''
      this.reason = ''
      this.storeId = ''
      this.storeName = ''
      this.handleQuery()
      const params = {
        level: this.level,
        cityId: this.cityId,
        salesArea: this.salesArea,
        type: this.type,
        timeType: this.timeType,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        storeId: this.storeId,
        storeName: this.storeName,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.$emit('reset', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.month_box {
  display: flex;
  align-items: center;
}
</style>
